import React from "react";

const AlertData = (props) => {
    return (
        <div className="alert alert-danger" role="alert">
            <h5 className="alert-heading">{props.title}</h5>
            <p>{props.description}<br />Time: {props.time}, Expires: {props.expires}</p>
        </div>
    );
}

export default AlertData;
