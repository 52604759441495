import React from "react";
import { Switch, Route } from 'react-router-dom'
import WeatherContainer from '../container/WeatherContainer'
import ConverterContainer from "../container/ConverterContainer";

const Routes = () => (
    <main>
        <Switch>
            <Route exact path='/' component={WeatherContainer}/>
            <Route exact path='/converter' component={ConverterContainer}/>
        </Switch>
    </main>
)

export default Routes
