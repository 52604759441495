import React, {Component} from 'react';
import axios from 'axios';
import Geocode from "react-geocode";
import Weather from '../components/weather/Weather';
import Current from '../components/weather/Current';
import CurrentDetails from '../components/weather/CurrentDetails';
import Hourly from '../components/weather/Hourly';
import HourlyData from '../components/weather/HourlyData';
import Daily from '../components/weather/Daily';
import DailyData from '../components/weather/DailyData';
import Alerts from '../components/weather/Alerts';
import AlertData from '../components/weather/AlertData';

class WeatherContainer extends Component {

    darkSkyApi = 'b2d09633f4c9016136e5e0b6d1542aac';
    googleKey = 'AIzaSyA8YvXQPv3pZwWtIPVIdXMxqZqygKB3x-Y';

    constructor(props) {
        super(props);

        this.state = {
            loadingAnimation: true,
            address: null,
            city: 'Budapest, HU',
            location: {
                city: 'Budapest, HU',
                state: '',
                lat: 47.49801,
                lng: 19.03991
            },
            current: {
                temperature: null,
                apparentTemperature: null,
                weather: null,
                icon: null,
                pressure: null,
                cloudCover: null,
                dewPoint: null,
                humidity: null,
                precipIntensity: null,
                ozone: null,
                nearestStormDistance: null,
                nearestStormDirection: null,
                snowfall: null,
                uvIndex: null,
                visibility: null,
                windSpeed: null,
                windGust: null,
                windBearing: null,
            },
            hourly: {
                summary: null,
                data: null
            },
            daily: {
                summary: null,
                data: null
            },
            alerts: null,
            zenQuote: {
                quote: null,
                author: null
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getCoordinates(){
        Geocode.setApiKey(this.googleKey);
        Geocode.fromAddress(this.state.location.city).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                let currentState = {...this.state};
                currentState.location.lat = lat;
                currentState.location.lng = lng;
                currentState.city=this.state.location.city;
                this.setState({
                    ...currentState
                });
                this.getWeather();
            },
            error => {
                alert("Unkown Place!");
            }
        );
    }

    getWeather() {
        axios.get('https://cors-anywhere.herokuapp.com/https://api.darksky.net/forecast/' + this.darkSkyApi + '/' + this.state.location.lat + ',' + this.state.location.lng + '?units=si')
            .then((res) => {
                let currentState = {...this.state};
                currentState.current.temperature = res.data.currently.temperature.toFixed(1);
                currentState.current.temperatureF = (res.data.currently.temperature.toFixed(1) * 1.8000 + 32.00).toFixed(1);
                currentState.current.apparentTemperature = res.data.currently.apparentTemperature.toFixed(1);
                currentState.current.apparentTemperatureF = (res.data.currently.apparentTemperature * 1.8000 + 32.00).toFixed(1);
                currentState.current.weather = res.data.currently.summary;
                currentState.current.icon = res.data.currently.icon;
                currentState.current.pressure = res.data.currently.pressure.toFixed(0);
                currentState.current.cloudCover = res.data.currently.cloudCover.toFixed(1);
                currentState.current.dewPoint = res.data.currently.dewPoint.toFixed(0);
                currentState.current.humidity = res.data.currently.humidity;
                currentState.current.precipIntensity = res.data.currently.precipIntensity;
                currentState.current.ozone = res.data.currently.ozone;
                currentState.current.nearestStormDistance = res.data.currently.nearestStormDistance;
                currentState.current.nearestStormDirection = res.data.currently.nearestStormDirection;
                currentState.current.snowfall = res.data.currently.snowfall;
                currentState.current.uvIndex = res.data.currently.uvIndex;
                currentState.current.visibility = res.data.currently.visibility.toFixed(0);
                currentState.current.windSpeed = res.data.currently.windSpeed;
                currentState.current.windGust = res.data.currently.windGust;
                currentState.current.windBearing = res.data.currently.windBearing;
                currentState.hourly.summary = res.data.hourly.summary;
                currentState.hourly.data = res.data.hourly.data;
                currentState.daily.summary = res.data.daily.summary;
                currentState.daily.data = res.data.daily.data;
                currentState.alerts = res.data.alerts;

                currentState.loadingAnimation = false;
                this.setState({
                    ...currentState
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    handleChange (event) {
        let currentState = {...this.state};
        currentState.location.city = event.target.value;
        this.setState({
            ...currentState
        });
    }

    handleSubmit (event) {
        this.getCoordinates();
        event.preventDefault();
    }

    componentDidMount() {
        this.getWeather();
    }

    render() {
        const loaderStyle = this.state.loadingAnimation ? {} : {display: 'none'};
        const hourlyData = [];
        const alerts = [];
        const dailyData = [];

        if (this.state.hourly.data) {
            this.state.hourly.data.forEach((data, i) => {
                let time = new Date(data.time * 1000).getHours();
                hourlyData.push(<HourlyData key={i}
                                            temperature={data.temperature.toFixed(1)}
                                            temperatureF={(data.temperature* 1.8000 + 32.00).toFixed(1)}
                                            time={time}
                                            icon={data.icon}
                />);
            });
        }
        if (this.state.alerts) {
            this.state.alerts.forEach((data, i) => {
                let time = new Date(data.time * 1000).toLocaleString();
                let expires = new Date(data.expires * 1000).toLocaleString();
                alerts.push(<AlertData key={i}
                                       title={data.title}
                                       description={data.description}
                                       time={time}
                                       expires={expires}
                />);
            });
        }
        if (this.state.daily.data) {
            this.state.daily.data.forEach((data, i) => {
                let time = new Date(data.time * 1000).toLocaleDateString();
                dailyData.push(<DailyData key={'daily-' + i}
                                          row={'daily-' + i}
                                          time={time}
                                          temperature={data.temperature}
                                          temperatureF={data.temperature}
                                          temperatureMin={data.temperatureMin.toFixed(1)}
                                          temperatureMinF={(data.temperatureMin * 1.8000 + 32.00).toFixed(1)}
                                          temperatureMax={data.temperatureMax.toFixed(1)}
                                          temperatureMaxF={(data.temperatureMax * 1.8000 + 32.00).toFixed(1)}
                                          icon={data.icon}
                                          descr={data.summary}
                                          apparentTemperature={data.apparentTemperature}
                                          pressure={data.pressure}
                                          cloudClover={data.cloudCover}
                                          dewPoint={data.dewPoint}
                                          humidity={data.humidity}
                                          precipIntensity={data.precipIntensity}
                                          ozone={data.ozone}
                                          nearestStormDistance={data.nearestStormDistance}
                                          nearestStormDirection={data.nearestStormDirection}
                                          snowfall={data.snowfall}
                                          uvIndex={data.uvIndex}
                                          visibility={data.visibility}
                                          windSpeed={data.windSpeed}
                                          windGust={data.windGust}
                                          windBearing={data.windBearing}
                />);
            });
        }
        return (
            <Weather>
                <div className="city-weather-temperature loader" style={loaderStyle}></div>
                <Current
                    location={this.state.city}
                    //time={new Date().toLocaleString()}
                    temperature={this.state.current.temperature}
                    temperatureF={this.state.current.temperatureF}
                    icon={this.state.current.icon}
                    descr={this.state.current.weather}
                    apparentTemperature={this.state.current.apparentTemperature}
                    apparentTemperatureF={this.state.current.apparentTemperatureF}
                />

                <Alerts data={alerts}/>
                <CurrentDetails
                    pressure={this.state.current.pressure}
                    cloudClover={this.state.current.cloudCover}
                    dewPoint={this.state.current.dewPoint}
                    humidity={this.state.current.humidity}
                    precipIntensity={this.state.current.precipIntensity}
                    ozone={this.state.current.ozone}
                    nearestStormDistance={this.state.current.nearestStormDistance}
                    nearestStormDirection={this.state.current.nearestStormDirection}
                    snowfall={this.state.current.snowfall}
                    uvIndex={this.state.current.uvIndex}
                    visibility={this.state.current.visibility}
                    windSpeed={this.state.current.windSpeed}
                    windGust={this.state.current.windGust}
                    windBearing={this.state.current.windBearing}
                />
                <Hourly summary={this.state.hourly.summary} data={hourlyData}/>
                <Daily summary={this.state.daily.summary} data={dailyData}/>
            </Weather>
        );
    }
}

export default WeatherContainer;
