import React from "react";

const ToSelect = (props) => {
    return (
        <select title="From" className="form-control green custom" name="unit-from" required onChange={props.handleToUnit}>
            {props.options}
        </select>
    );
}

export default ToSelect;
