import React from "react";

const Current = (props) => {
    return (
        <div>
            <div className="row current-data">
                <div className="col-5 text-center">
                    <h5 className="location">{props.location}</h5>
                    <div className={'icon wi wi-forecast-io-' + props.icon}></div>
                    <div className="weather-desc">
                        <div className="description">{props.descr}</div>
                    </div>
                </div>
                <div className="col-7 text-md-right text-center">
                    {props.temperature ? <p className="temperature"> {props.temperature}&deg;C<br/>
                        <span className="temperature-feels">{props.temperatureF} F</span>
                        <span className="temperature-feels">Feels Like {props.apparentTemperature}&deg;C / {props.apparentTemperatureF}&nbsp;F</span>
                    </p> : null}
                </div>
            </div>
        </div>);
}

export default Current;
