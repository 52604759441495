import React from "react";

const Hourly = (props) => {
    return (
        <div>
            <hr />
            <div className="row m15">
                <div className="col-12">
                    <h5 className="text-center">Hourly</h5>
                    <p className="summary text-center">{props.summary}</p>
                </div>
            </div>
            <div className="row flex-row flex-nowrap scrolling-wrapper m15">{props.data}</div>
        </div>
    );
}

export default Hourly;
