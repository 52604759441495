import React from "react";

const Daily = (props) => {
    return (
        <div>
            <hr />
            <div className="row m15">
                <div className="col-12 text-center"><h5>Daily</h5>
                    <p>{props.summary}</p>
                </div>
            </div>
            <div id="accordion" className="accordion">
                <div className="card mb-0">
                    {props.data}
                </div>
            </div>
        </div>
    );
}

export default Daily;
