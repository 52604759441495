import React from "react";
import '../../assets/css/Weather-icons.css';
import '../../assets/css/Weather-icons-wind.min.css';
import '../../assets/css/Weather.css';
import '../../assets/css/Font-awesome.min.css';

const Weather = (props) => {
    return (
        <div className="container">
            <div className="main-wrapper">
                {props.children}
            </div>
        </div>
    );
}

export default Weather;
