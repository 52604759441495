import React from "react";

const FromSelect = (props) => {
    return (
        <select title="From" className="form-control blue custom" name="unit-from" required onChange={props.handleFromUnit}>
            {props.options}
        </select>
    );
}

export default FromSelect;
