import React, {Component} from 'react';
import Converter from "../components/converter/Converter";

class ConverterContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            types: null
        }
    }

    componentDidMount() {
       /* this.getWeather();*/
    }

    render() {
        return (
            <Converter />
           );
    }
}

export default ConverterContainer;
