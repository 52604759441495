import React from "react";

const Alerts = (props) => {
    return (
        <div>
            {props.data}
        </div>
    );
}

export default Alerts;
