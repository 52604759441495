import React, {useState} from "react";
import '../../assets/css/Converter.css';
import converter from 'convert-units';
import FromSelect from "./FromSelect";
import SelectOptions from "./SelectOptions";
import ToSelect from "./ToSelect";
import btnimage from '../../assets/img/btn.png';

const Converter = () => {

    const [selectOptions, setSelectOptions] = useState('');
    const [fromUnit, setFromUnit] = useState('');
    const [toUnit, setToUnit] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setTo(converter(from).from(fromUnit).to(toUnit));
    }

    const handleSelectUnits = (e) => {
        let value="length";
        if(e.target.value){
            value=e.target.value;
        }
        let converterValue = converter().list(value);

        let excludes = ["ft-us", "R", "km3", "mcg", "t", "ns", "mu", "mm3", "kl", "krm", "tsk", "msk", "kkp", "glas", "kanna", "fl-oz", "qt"];

        if (converterValue) {
            const selectData = [];
            converterValue.forEach((data, i) => {
                if (excludes.includes(data.abbr) === false) {
                    if (i === 0) {
                        setFromUnit(data.abbr);
                        setToUnit(data.abbr);
                    }
                    if(value==="area" && data.abbr==="cm2"){
                        data.singular="Square Centimeter";
                    }
                    selectData.push(<SelectOptions key={i}
                                                   value={data.abbr}
                                                   name={data.singular}
                    />);
                }
            });
            setSelectOptions(selectData);
        }
    }

    const handleFromUnit = (e) => {
        setFromUnit(e.target.value);
    }

    const handleToUnit = (e) => {
        setToUnit(e.target.value);
    }

    const handleFrom = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFrom(e.target.value);
        }
    }

    return (
        <div className="container converter">
            <div className="main-wrapper text-center">
                <form onSubmit={handleSubmit} onLoad={handleSelectUnits}>
                    <div className="form-group">
                        <select title="Unit" className="form-control blue custom" name="unitType"
                                onChange={handleSelectUnits}>
                            <option value="length">Length</option>
                            <option value="temperature">Temperature</option>
                            <option value="area">Area</option>
                            <option value="volume">Volume</option>
                            <option value="mass">Weight</option>
                            <option value="time">Time</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <FromSelect options={selectOptions} handleFromUnit={handleFromUnit}/>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control blue custom" value={from} onChange={handleFrom}/>
                    </div>
                    <div className="form-group">
                        <p>convert to</p>
                    </div>
                    <div className="form-group">
                        <ToSelect options={selectOptions} handleToUnit={handleToUnit}/>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control green custom" value={to} readOnly/>
                    </div>
                    <button type="submit" className="btn form-control yellow"><img src={btnimage} alt=""/> Convert
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Converter;
