import React from "react";

const HourlyData = (props) => {
    return (
        <div className="col-6 col-sm-4 col-lg-2 m15">
            <div className="inner">
                <h5 className="week-day">{props.time} h</h5>
                <div className={'icon wi wi-forecast-io-' + props.icon}></div>
                <p className="week-day-temperature">{props.temperature} &deg;C <br />{props.temperatureF} F</p>
            </div>
        </div>
    );
}

export default HourlyData;
