import React from "react";

const CurrentDetails = (props) => {
    return (
            <div className="col-12 text-center">
                <ul className="weather-params" id="weather-params">
                    {props.pressure ?<li className="pressure">Pressure: <span>{props.pressure} hPa</span></li>: null}
                    {props.dewPoint ?<li className="dew-point">Dew pt.: <span>{props.dewPoint} &deg;</span></li>: null}
                    {props.humidity ?<li className="humidity">Dew pt.: <span>{props.humidity} %</span></li>: null}
                    {props.precipIntensity ?<li className="presip-intensity">Precip Intensity: <span>{props.precipIntensity}</span></li>: null}
                    {props.nearestStormDistance ?<li className="storm-distance">Nearest Storm Distance: <span>{props.nearestStormDistance}</span></li>: null}
                    {props.nearestStormDirection ?<li className="storm-direction">Nearest Storm Direction: <span>{props.nearestStormDirection}</span></li>: null}
                    {props.snowfall ?<li className="snowfall">Snowfall: <span>{props.snowfall}</span></li>: null}
                    {props.uvIndex ?<li className="uv-index">UV Index: <span>{props.uvIndex}</span></li>: null}
                    {props.windSpeed ?<li className="wind-speed">Wind Speed: <span>{props.windSpeed} kph</span></li>: null}
                </ul>
        </div>);
}

export default CurrentDetails;
