import React from 'react';
import Routes from './components/Routes';
import './assets/css/App.css';

function App() {
  return (
      <div className="App">
        <Routes />
      </div>
  );
}

export default App;
