import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const DailyData = (props) => {
    return (
        <Accordion>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={props.row}>
                    <div className={'icon wi wi-forecast-io-' + props.icon + ' float-left'}></div>
                    <div className="title-data float-left">{props.time}<br /> min: {props.temperatureMin} &deg; / {props.temperatureMinF} F, max: {props.temperatureMax} &deg; / {props.temperatureMaxF} F</div>
                    <div className="right-arrow float-right"><FontAwesomeIcon icon={faArrowRight} /></div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={props.row}>
                    <Card.Body>
                        <p className="description">{props.descr}</p>
                        <ul className="weather-params">
                            {props.pressure ?<li className="pressure">Pressure: <span>{props.pressure} hPa</span></li>: null}
                            {props.dewPoint ?<li className="dew-point">Dew pt.: <span>{props.dewPoint} &deg;</span></li>: null}
                            {props.humidity ?<li className="humidity">Dew pt.: <span>{props.humidity} %</span></li>: null}
                            {props.precipIntensity ?<li className="presip-intensity">Precip Intensity: <span>{props.precipIntensity}</span></li>: null}
                            {props.nearestStormDistance ?<li className="storm-distance">Nearest Storm Distance: <span>{props.nearestStormDistance}</span></li>: null}
                            {props.nearestStormDirection ?<li className="storm-direction">Nearest Storm Direction: <span>{props.nearestStormDirection}</span></li>: null}
                            {props.snowfall ?<li className="snowfall">Snowfall: <span>{props.snowfall}</span></li>: null}
                            {props.uvIndex ?<li className="uv-index">UV Index: <span>{props.uvIndex}</span></li>: null}
                            {props.windSpeed ?<li className="wind-speed">Wind Speed: <span>{props.windSpeed} kph</span></li>: null}
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>

        /*

        <div>
            <div className="card-header collapsed" data-toggle="collapse" data-target={'#collapse' + props.row}>
                <a href={'#collapse' + props.row} className="card-title">
                    <div className={'icon wi wi-forecast-io-' + props.icon + ' float-left'}></div>
                    <div className="title-data float-left">{props.time}, min: {props.temperatureMin} &deg;, max: {props.temperatureMax} &deg;</div>
                </a>
            </div>
            <div id={'collapse' + props.row} className="card-body collapse" data-parent="#accordion">
                <p className="description">{props.descr}</p>
                <ul className="weather-params">
                    {props.pressure ?<li className="pressure">Pressure: <span>{props.pressure} hPa</span></li>: null}
                    {props.dewPoint ?<li className="dew-point">Dew pt.: <span>{props.dewPoint} &deg;</span></li>: null}
                    {props.humidity ?<li className="humidity">Dew pt.: <span>{props.humidity} %</span></li>: null}
                    {props.precipIntensity ?<li className="presip-intensity">Precip Intensity: <span>{props.precipIntensity}</span></li>: null}
                    {props.nearestStormDistance ?<li className="storm-distance">Nearest Storm Distance: <span>{props.nearestStormDistance}</span></li>: null}
                    {props.nearestStormDirection ?<li className="storm-direction">Nearest Storm Direction: <span>{props.nearestStormDirection}</span></li>: null}
                    {props.snowfall ?<li className="snowfall">Snowfall: <span>{props.snowfall}</span></li>: null}
                    {props.uvIndex ?<li className="uv-index">UV Index: <span>{props.uvIndex}</span></li>: null}
                    {props.visibility ?<li className="visibility">Visibility: <span>{props.visibility}</span></li>: null}
                    {props.windSpeed ?<li className="wind-speed">Wind Speed: <span>{props.windSpeed} kph</span></li>: null}
                    {props.windGust ?<li className="wind-gust">Wind Gust: <span>{props.windGust}</span></li>: null}
                    {props.windBearing ?<li className="wind-bearing">Wind Bearing: <span>{props.windBearing}</span></li>: null}
                </ul>
            </div>
        </div>
         */
    );
}

export default DailyData;
